import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  AuthPage,
  GaragePage,
  DateAndTimeVisitPage,
  NotFoundPage,
  NewUserPage,
  AddAutoPage,
  AddRunPage,
  ServicesPage,
  WashingPage,
  ServiceCentersPage,
  ConfirmPage,
  SelectServiceCenterPage,
  DoneApplicationPage,
  ContactsPage,
  AddLicensePlatePage,
  // LandingPage,
} from '../pages';

import { GlobalLayout } from './layouts';
import { SplashScreen } from './ui';

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const isMobile: boolean = window.innerWidth <= 768;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    (isMobile && isLoading) ? (
      <SplashScreen />
    ) : (
      <Routes>
        <Route path="/" element={<GlobalLayout />}>
          <Route index element={<GaragePage />} />
          <Route path="auth" element={<AuthPage />} />
          <Route path="date-and-time-visit" element={<DateAndTimeVisitPage />} />
          <Route path="new-user" element={<NewUserPage />} />
          <Route path="add-auto" element={<AddAutoPage />} />
          <Route path="add-run/:id" element={<AddRunPage />} />
          <Route path="add-license-plate/:id" element={<AddLicensePlatePage />} />
          <Route path="services/:id" element={<ServicesPage />} />
          <Route path="washing/:id" element={<WashingPage />} />
          <Route path="select-service-center" element={<SelectServiceCenterPage />} />
          <Route path="confirm" element={<ConfirmPage />} />
          <Route path="service-centers" element={<ServiceCentersPage />} />
          <Route path="done" element={<DoneApplicationPage />} />
          <Route path="contacts" element={<ContactsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    )
  );
};

export default App;
