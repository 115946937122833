import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import dayjs, { Dayjs } from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import 'dayjs/locale/ru';

import { Button } from '@mui/material';

import arrowLeft from '../../assets/icons/icon_arrow_left.svg';
import { useAppDispatch } from '../../shared/config/hooks';
import { setDateAndTime } from '../../shared/store/slices/globalSlice';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

dayjs.locale('ru');

const DateAndTimeVisitPage: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [availableTime, setAvailableTime] = useState<string[]>([]);
  const [disabledDates, setDisabledDates] = useState<Dayjs[]>([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChangeDay = (newValue: Dayjs | null) => {
    setSelectedDate(newValue);
    setSelectedTime(null);
  };

  const handleSelectTime = (time: string) => {
    setSelectedTime(time);
  };

  const shouldDisableDate = (date: Dayjs) => {
    const today = dayjs().startOf('day');
    // const isWeekend = date.day() === 0 || date.day() === 6;
    const isDisabled = disabledDates.some((disabledDate) => disabledDate.isSame(date, 'day'));
    return date.isSame(today, 'day') || /* isWeekend || */ isDisabled;
  };

  const generateAvailableTime = (date: Dayjs) => {
    const startHour = 9;
    const endHour = 18;

    const times: string[] = [];
    const now = dayjs();
    const nextDay = now.add(1, 'day').startOf('day');

    const currentHour = now.hour();
    const dayOfWeek = date.day();

    // ПН - ВС
    if (dayOfWeek >= 0 && dayOfWeek <= 6) {
      if (date.isSame(nextDay, 'day')) {
        if (currentHour >= endHour) return times;

        const bookingStartHour = currentHour >= startHour ? currentHour + 1 : startHour;
        // eslint-disable-next-line no-plusplus
        for (let hour = bookingStartHour; hour <= endHour; hour++) {
          times.push(`${hour}:00`);
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let hour = startHour; hour <= endHour; hour++) {
          times.push(`${hour}:00`);
        }
      }
    }

    return times;
  };

  useEffect(() => {
    const nextDay = dayjs().add(1, 'day').startOf('day');
    const availableTimeForNextDay = generateAvailableTime(nextDay);
    if (availableTimeForNextDay.length === 0) {
      setDisabledDates((prev) => [...prev, nextDay]);
    }

    if (selectedDate) {
      const newAvailableTime = generateAvailableTime(selectedDate);
      setAvailableTime(newAvailableTime);
    } else {
      setAvailableTime([]);
    }
  }, [selectedDate]);

  const handleNext = () => {
    if (selectedDate && selectedTime) {
      const isoDateTime = dayjs(`${selectedDate.format('YYYY-MM-DD')} ${selectedTime}`).toISOString();
      dispatch(setDateAndTime(isoDateTime));
      navigate('/confirm');
    }
  };

  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('date-time__container')}>
        <Link to={'/select-service-center'} className={cx('link-goto')}>
          <img src={arrowLeft} alt="Назад к выбору СЦ" />
          Назад к выбору СЦ
        </Link>
        <div className={cx('heading')}>
          <h2>Выберите дату и время визита</h2>
        </div>
        <div className={cx('date-wrapper')}>
          <h3>Дата</h3>
          <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterDayjs}>
            <div className={cx('date-wrapper__calendar')}>
              <DateCalendar
                disablePast
                value={selectedDate}
                onChange={(newValue) => handleChangeDay(newValue)}
                views={['day']}
                dayOfWeekFormatter={(weekday) => `${weekday.format('dd')}`}
                shouldDisableDate={shouldDisableDate}
              />
            </div>
          </LocalizationProvider>
        </div>
        <div className={cx('time-wrapper')}>
          <h3>Время</h3>
          <ul className={cx('time-list')}>
            {availableTime.map((time) => (
              <li key={time} onClick={() => handleSelectTime(time)} className={cx({ selected: selectedTime === time })}>
                {time}
              </li>
            ))}
          </ul>
        </div>
        <div className={cx('buttons-group')}>
          <Button
            variant="contained"
            type="submit"
            className={cx('button', 'button--next')}
            disabled={!selectedDate || !selectedTime}
            onClick={handleNext}
          >
            Далее
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DateAndTimeVisitPage;
